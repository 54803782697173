<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu class="left-menu-box" title="知识产权" :menuData="menuData"></vertical-menu>
        <div class="right-list-box">
          <div class="list">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <img src="../../static/images/organizationLogo.png" height="70"/>
              <div class="des">
                <div class="item-title">{{item.title}}</div>
                <div class="time">{{item.time}}</div>
                <div class="item-bottum">
                  <button class="detail-but" @click="handleDetailClick(item)">查看详情</button>
                </div>

              </div>

            </div>
          </div>
<!--          <article-list :data="dataList" @handleClick="handleDetailClick"></article-list>-->
          <div class="footer">
            <div class="left">
              共 1000 条
            </div>
            <el-pagination
                background
                layout="prev, pager, next"
                :total="1000">
            </el-pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
export default {
  name: 'organizationList',
  components: {
    articleList,verticalMenu
  },
  data() {
    return {
      menuData:[
        {
          'name':'合作机构',
          'code':'tex'
        }
      ],
      dataList: [
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 1
        },
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 2
        },
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 3
        },
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 4
        },
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 5
        },
        {
          title: '成都众恒智合专利代理事务所(普通合伙)',
          time: '众恒知识产权成立于 2013 。现包含成都众恒知识产权 代理有限公司，成都众恒智合专利代理事务所（普通合伙），成都众恒智合信息技 术有限公司三家独立子公司，及成都众恒智合专利代理事务所（普通合伙）重庆分所...',
          id: 6
        }
      ]
    }
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {

  },
  methods: {
    handleDetailClick() {
      this.$router.push("/intellectualProperty/organizationDetail");
    }
  }
}
</script>
<style scoped lang="less">
.policy-article-wrapper{
  display: flex;
  flex-direction: row;
  .left{

  }
  .right{

  }
}
.list {
  padding: 20px;
  background-color: #fff;
  font-size: 16px;
  .item {
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 30px 20px 30px 30px;
    display: flex;
    justify-content: space-around;
    .des{
      flex: 0.9;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .time {
        padding-top: 10px;
        display: inline-block;
        font-size: 12px;
      }
      .item-title {
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
      }
      .item-bottum{
        width: 100%;
        text-align: right;
        .detail-but{
          background-color: white;
          border: 1px solid grey;
          color: black;
          width: 100px;
        }
      }

    }

  }
  //.item::after {
  //  position: absolute;
  //  top: 40px;
  //  left: 20px;
  //  height: 4px;
  //  width: 4px;
  //  content: "";
  //  border-radius: 50%;
  //  background: #666;
  //}
  .item:hover {
    color: #27A5F9;
    background: #EEF8FF;
    transform: scaleX(1.045);
    .item-bottum{
      width: 100%;
      text-align: right;
      .detail-but{
        background-color: blue;
        border: 1px solid grey;
        color: white;
        width: 100px;
        cursor: pointer;
      }
    }
  }
}
</style>

